import React from 'react';

//react-router-dom
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';

import NavigationDrawer from './NavigationDrawer';
import CustomerDashboard from './CustomerDashboard';
import ProductCustomerDashboard from './ProductCustomerDashboard';
import { useTheme } from '@mui/styles';
//react-redux
import { connect } from 'react-redux';
import { drawerWidth } from "../configuration/drawerConfiguration";
import { Box } from '@mui/material';
const CustomerDeliveryPortal = ({ drawerOpen }) => {

    const theme = useTheme();

    const sxContent = {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(8),
        marginLeft: drawerWidth,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        }

    }
    return (
        <Router>
            <NavigationDrawer />
            <main style={sxContent}>
                <Switch>
                    <Route exact path="/products-and-customers" component={ProductCustomerDashboard} />
                    <Route path="/" component={CustomerDashboard} />
                </Switch>
            </main>
        </Router >
    )
}

const mapStateToProps = state => ({
    drawerOpen: state.appBarTabs.drawerOpen,
});

export default connect(mapStateToProps)(CustomerDeliveryPortal);