import React from 'react';

//material-ui
import TableCell from '@mui/material/TableCell'

const tableCellStyle = {
    fontSize: "small",
    paddingTop: "1px",
    paddingBottom: "1px",
    "& .MuiFormControl-root": {
        width: "100%"
    }
}
const SmallTableCell = React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    return <TableCell ref={ref} sx={tableCellStyle} {...rest}>{children}</TableCell>
});

export default SmallTableCell;