import React from 'react';

//material-ui
import TextField from '@mui/material/TextField';

const ShrinkedTextField = props => {
    return (
        <TextField 
            InputLabelProps={{shrink: true}}
            {...props}
        />
    )
}

export default ShrinkedTextField;