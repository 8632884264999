import React, { useReducer, useEffect } from 'react';

import { JNPDialog } from '../dialogs'

// material-ui
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from "material-table";
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import Snackbar from '@material-ui/core/Snackbar';
import MyAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
//forms
import CreateCustomerForm from '../forms/CreateCustomerForm';
import DeliveryForm from '../forms/DeliveryForm';

//axios
import { AxiosInstance } from 'jnpsoft-react-utilities/dist/AppBar';

//utilities
import { toEnumerationString, exportCsv } from '../utilities';


const ATTRIBUTES_LIST = [
    "partcat_version",
    "database_type",
    "ktype_delivery"
]

const API_URL = process.env.REACT_APP_CUSTOMER_DELIVERY_API_URL;

const converter = customer => {
    if (customer) {
        const { products, customerContacts, customer_attributes, ...customerInfo } = customer;
        return {
            products: products,
            customerContacts: customerContacts,
            customerInfo: { ...customerInfo, ...customer_attributes }
        }
    }
}

const OPEN_CREATE_CUSTOMER_FORM = "OPEN_CREATE_CUSTOMER_FORM";
const CLOSE_CREATE_CUSTOMER_FORM = "CLOSE_CREATE_CUSTOMER_FORM";
const CLOSE_CREATE_CUSTOMER_FORM_ERROR = "CLOSE_CREATE_CUSTOMER_FORM_ERROR";

const SET_CUSTOMERS = "SET_CUSTOMERS";
const UPDATE_CUSTOMERS = "UPDATE_CUSTOMER";

const OPEN_DELETE_CUSTOMER_DIALOG = "OPEN_DELETE_CUSTOMER_DIALOG";
const CLOSE_DELETE_CUSTOMER_DIALOG = "CLOSE_DELETE_CUSTOMER_DIALOG"

const OPEN_MAKE_DELIVERY_FORM = "OPEN_MAKE_DELIVERY_FORM";
const CLOSE_MAKE_DELIVERY_FORM = "CLOSE_MAKE_DELIVERY_FORM";
const CLOSE_MAKE_DELIVERY_FORM_ERROR = "CLOSE_MAKE_DELIVERY_FORM_ERROR";

const CLOSE_INFORMATION_VIEW = "CLOSE_INFORMATION_VIEW";

const initialState = {
    createCustomerFormOpen: false,
    customers: [],
    customer: {},
    makeDeliveryFormOpen: false,
    deleteUserDialogOpen: false,
    snackbar: {
        message: null,
        type: "INFO"
    }

}

const reducer = (state, action) => {
    switch (action.type) {
        case OPEN_CREATE_CUSTOMER_FORM: {
            return { ...state, createCustomerFormOpen: true, customer: action.payload };
        }
        case CLOSE_CREATE_CUSTOMER_FORM: {
            return { ...state, createCustomerFormOpen: false, snackbar: action.payload };
        }
        case CLOSE_CREATE_CUSTOMER_FORM_ERROR: {
            return { ...state, snackbar: action.payload };
        }
        case OPEN_DELETE_CUSTOMER_DIALOG: {
            return { ...state, deleteUserDialogOpen: true, customer: action.payload };
        }
        case CLOSE_DELETE_CUSTOMER_DIALOG: {
            return { ...state, deleteUserDialogOpen: false, snackbar: action.payload };
        }
        case SET_CUSTOMERS: {
            return { ...state, customers: action.payload };
        }
        case UPDATE_CUSTOMERS: {
            return { ...state, customers: action.payload, createCustomerFormOpen: false };
        }
        case OPEN_MAKE_DELIVERY_FORM: {
            return { ...state, makeDeliveryFormOpen: true, customer: action.payload };
        }
        case CLOSE_MAKE_DELIVERY_FORM: {
            return { ...state, makeDeliveryFormOpen: false, snackbar: action.payload };
        }
        case CLOSE_MAKE_DELIVERY_FORM_ERROR: {
            return { ...state, snackbar: action.payload };
        }
        case CLOSE_INFORMATION_VIEW: {
            return { ...state, snackbar: initialState.snackbar };
        }
        default: {
            return state;
        }
    }
}

const Alert = props => <MyAlert elevation={6} variant="filled" {...props} />

const CustomersDashboard = () => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const { createCustomerFormOpen, customers, customer, makeDeliveryFormOpen, deleteUserDialogOpen, snackbar } = state;

    const handleOpenCreateCustomer = customer => {
        dispatch({
            type: OPEN_CREATE_CUSTOMER_FORM,
            payload: customer
        });
    }

    const HandleActionsCell = ({
        value: initialValue,
        row: { index },
        data: data
    }) => {

        return (
            <Box>
                <IconButton onClick={() => handleOpenCreateCustomer(converter(data[index]))}> <EditIcon fontSize="inherit"  /></IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(data[index])} ><DeleteIcon fontSize="inherit" /></IconButton>
                <IconButton onClick={() => handleOpenDelivery(data[index])}><SendIcon fontSize="inherit" /></IconButton>
            </Box>
        )
    }
    const HandleIsActiveCell = ({
        value: initialValue,
        row: { index },
        data: data,
    }) => {

        return (

            <Checkbox checked={Boolean(data[index].is_active ? data[index].is_active : false)} disabled={true} />
        )
    }

    const handleCloseCreateCustomer = (message = null, type = "INFO", error = false) => dispatch({
        type: error ? CLOSE_CREATE_CUSTOMER_FORM_ERROR : CLOSE_CREATE_CUSTOMER_FORM,
        payload: { message, type }
    });

    const handleUpdateCustomers = customer => {
        const saveCustomer = async customer => {
            await AxiosInstance
                .post(API_URL + "/customers", customer)
                .then(response => response.data)
                .then(data => {
                    handleCloseCreateCustomer(data, "SUCCESS");
                })
                .catch(error => {
                    console.log(error);
                    handleCloseCreateCustomer(error.message, "ERROR", true);
                })
        }

        let attributes = {}
        Object
            .keys(customer)
            .filter(item => ATTRIBUTES_LIST.includes(item.toLowerCase()))
            .forEach(item => attributes[item] = customer[item]);

        saveCustomer({ ...customer, customer_attributes: attributes });
    }

    const handleCloseDeleteDialog = (message = null, type = "INFO") => {
        dispatch({
            type: CLOSE_DELETE_CUSTOMER_DIALOG,
            payload: { message, type }
        });
    }

    const handleOpenDeleteDialog = customer => {
        dispatch({
            type: OPEN_DELETE_CUSTOMER_DIALOG,
            payload: customer
        })
    };

    const handleDeleteCustomer = () => {
        const deleteCustomer = async customer_id => {
            await AxiosInstance
                .delete(API_URL + "/customers/" + customer_id)
                .then(response => response.data)
                .then(data => {
                    handleCloseDeleteDialog(data, "SUCCESS");
                })
                .catch(error => {
                    console.log(error);
                    handleCloseDeleteDialog(error.message, "ERROR");
                })
        }
        deleteCustomer(customer.customer_id);
    }

    const handleOpenDelivery = customer => {
        dispatch({
            type: OPEN_MAKE_DELIVERY_FORM,
            payload: customer
        });
    }

    const handleCloseDelivery = (message = null, type = "INFO", error = false) => dispatch({
        type: error ? CLOSE_MAKE_DELIVERY_FORM_ERROR : CLOSE_MAKE_DELIVERY_FORM,
        payload: { message, type }
    });

    const handleMakeDelivery = delivery => {
        const saveDelivery = async delivery => {
            await AxiosInstance
                .post(API_URL + "/make_deliveries", delivery)
                .then(response => response.data)
                .then(data => {
                    handleCloseDelivery(data, "SUCCESS");
                })
                .catch(error => {
                    console.log(error);
                    handleCloseDelivery(error.message, "ERROR", true);
                })
        }
        saveDelivery(delivery);
    }

    const handleCloseInformationView = () => dispatch({ type: CLOSE_INFORMATION_VIEW });

    useEffect(() => {
        const loadCustomer = async () => {
            await AxiosInstance
                .get(API_URL + "/customers")
                .then(response => response.data)
                .then(data => dispatch({
                    type: SET_CUSTOMERS,
                    payload: data
                }))
                .catch(error => console.log(error));
        }
        if (createCustomerFormOpen === false && deleteUserDialogOpen === false) {
            loadCustomer();
        }
    }, [createCustomerFormOpen, deleteUserDialogOpen])

    const columnDefs = [

        { Header: "Name", accessor: "customer_name", width: 350 },
        { Header: "Type", accessor: "customer_types", width: 50 },
        { Header: "Frequency", accessor: "frequency", width: 50 },
        { Header: "Data Type", accessor: "formats", width: 250 },
        {
            Header: "Active",
            accessor: "is_active",
            width: 75,
            Cell: HandleIsActiveCell
        },
        {
            Header: "Actions",
            id: 6,
            width: 112,
            minWidth: 112,
            accessor: '',
            Cell: HandleActionsCell,
        },
    ];

    const setSelectedRows = (selectedFlatRows) => { };

    return (

        <Box width="100%">
            <Box sx={{justifyContent: "flex-end", display: 'flex'}}>
                <Button sx={{
                    backgroundColor: "#ff562b",
                    color: "#fff",
                    ':hover':{
                        backgroundColor:"#1565c0"
                    },
                    mb:2,

                }}
                    onClick={() => handleOpenCreateCustomer()} >
                    Add New Customer
                </Button>
            </Box>
            <DivTableStyles style={{height:"initial"}}>
                <JnpDivTable
                    columns={columnDefs}
                    data={customers}
                    selectable={false}
                    disableSelect={true}
                    setSelectedRows={setSelectedRows}
                    defaultSortColumn="customer_name"
                    title="Customers"
                    sx={{ padding: 0 }}
                />
            </DivTableStyles>
            <JNPDialog
                onClose={() => handleCloseCreateCustomer()}
                open={createCustomerFormOpen}
                maxWidth="lg"
            >
                <CreateCustomerForm handleUpdateCustomers={handleUpdateCustomers} customer={customer} />
            </JNPDialog>
            <JNPDialog
                onClose={() => handleCloseDelivery()}
                open={makeDeliveryFormOpen}
                maxWidth="lg"
            >
                <Typography variant="h4">Overview</Typography>
                <DeliveryForm handleMakeDelivery={handleMakeDelivery} customer={customer} />
            </JNPDialog>
            <JNPDialog
                onClose={() => handleCloseDeleteDialog()}
                open={deleteUserDialogOpen}
                maxWidth="sm"
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box paddingBottom="16px">
                        <Typography>This user is about to be deleted. Are you sure?</Typography>
                    </Box>
                    <Box width="80%" display="flex" justifyContent="space-between">
                        <Button onClick={() => handleCloseDeleteDialog()}>No</Button>
                        <Button onClick={handleDeleteCustomer}>Yes</Button>
                    </Box>
                </Box>
            </JNPDialog>
            <Snackbar
                open={snackbar.message ? true : false}
                autoHideDuration={3000}
                onClose={handleCloseInformationView}
            >
                <Alert onClose={handleCloseInformationView} severity={snackbar.type.toLowerCase()}>{snackbar.message}</Alert>
            </Snackbar>
            <Backdrop open={false} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default CustomersDashboard