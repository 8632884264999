import React, {useReducer, useEffect} from 'react';

//material-ui
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import SmallTableCell from '../components/SmallTableCell';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//oe-product-chooser
import ProductChooserDialog from 'jnpsoft-react-utilities/dist/VirtualizedProductLineChooser';

//jnpsoft-react-utilities
import {AxiosInstance} from 'jnpsoft-react-utilities/dist/AppBar';

const SET_YEAR_FROM = "SET_YEAR_FROM";
const SET_YEAR_TO = "SET_YEAR_TO";
const SET_EDIT_INDEX_YEAR_FROM = "SET_EDIT_INDEX_YEAR_FROM";
const SET_EDIT_INDEX_YEAR_TO = "SET_EDIT_INDEX_YEAR_TO";
const RESET = "RESET";

const initialState = {
    yearFrom: "",
    yearTo: "",
    editIndexYearFrom: -1,
    editIndexYearTo: -1
}

const reducer = (state, action) => {
    switch(action.type) {
        case SET_YEAR_FROM: {
            return {...state, yearFrom: action.payload};
        }
        case SET_YEAR_TO: {
            return {...state, yearTo: action.payload};
        }
        case SET_EDIT_INDEX_YEAR_FROM: {
            return {...state, editIndexYearFrom: action.payload};
        }
        case SET_EDIT_INDEX_YEAR_TO: {
            return {...state, editIndexYearTo: action.payload};
        }        
        case RESET: {
            return {editIndexYearFrom: -1, editIndexYearTo: -1};
        }                        
        default: {
            return state;
        }
    }
}

const ProductForm = props => {

    const {products, isProductChooserOpen, handleSelectClose, handleClose, handleOpen} = props;

    const [state, dispatch] = useReducer(reducer, initialState);
    const {yearFrom, yearTo, editIndexYearFrom, editIndexYearTo} = state;

    const handleYearOnBlur = (event, index, yearType) => {
        let newProducts = [...products]
        const yearValue = event.target.value;
        newProducts[index][yearType] = yearValue;
        handleSelectClose(newProducts);
    }

    const handleYearFromOnBlur = (event, index) => handleYearOnBlur(event, index, "yearFrom");
    const handleYearToOnBlur = (event, index) => handleYearOnBlur(event, index, "yearTo");

    const handleYearFromOnChange = event => dispatch({
        type: SET_YEAR_FROM,
        payload: event.target.value
    });
    const handleYearToOnChange = event => dispatch({
        type: SET_YEAR_TO,
        payload: event.target.value
    });

    const handleOnFocusYearFrom = index => dispatch({
        type: SET_EDIT_INDEX_YEAR_FROM,
        payload: index
    });

    const handleOnFocusYearTo = index => dispatch({
        type: SET_EDIT_INDEX_YEAR_TO,
        payload: index
    });

    useEffect(() => {
        dispatch({type: RESET});
    }, [products]);

    return (
        <Box display="flex" height="100%" flexDirection="column" justifyContent="center">
            <Box flexGrow={1}>
                <TableContainer>
                    <Table
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                <SmallTableCell>IDs</SmallTableCell>
                                <SmallTableCell>Products</SmallTableCell>
                                <SmallTableCell>Categories</SmallTableCell>
                                <SmallTableCell>SubCategories</SmallTableCell>
                                <SmallTableCell>Regions</SmallTableCell>
                                <SmallTableCell>Year From</SmallTableCell>
                                <SmallTableCell>Year To</SmallTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            products
                            .sort((p1, p2) => {
                                if(p1.partTerminologyName !== p2.partTerminologyName) {
                                    return p1.partTerminologyName.localeCompare(p2.partTerminologyName);
                                }
                                return p1.region.localeCompare(p2.region);
                            })
                            .map((product, index) => 
                                <TableRow key={index}>
                                    <SmallTableCell>{product.partTerminologyID}</SmallTableCell>
                                    <SmallTableCell>{product.partTerminologyName}</SmallTableCell>
                                    <SmallTableCell>{product.categoryName}</SmallTableCell>
                                    <SmallTableCell>{product.subCategoryName}</SmallTableCell>
                                    <SmallTableCell>{product.region}</SmallTableCell>
                                    <SmallTableCell>
                                        <TextField 
                                            variant="standard"
                                            onBlur={event => handleYearFromOnBlur(event, index)}
                                            onFocus={() => handleOnFocusYearFrom(index)}
                                            onChange={handleYearFromOnChange}
                                            value={editIndexYearFrom === index ? yearFrom : product.yearFrom ? product.yearFrom : ""}
                                            inputProps={{
                                                style: {
                                                    maxWidth: "75px",
                                                    fontSize: "small"
                                                }
                                            }}
                                        />
                                    </SmallTableCell>
                                    <SmallTableCell>
                                        <TextField
                                            variant="standard"
                                            onBlur={event => handleYearToOnBlur(event, index)}
                                            onFocus={() => handleOnFocusYearTo(index)}
                                            onChange={handleYearToOnChange}
                                            value={editIndexYearTo === index ? yearTo: product.yearTo ? product.yearTo : ""}
                                            inputProps={{
                                                style: {
                                                    maxWidth: "75px",
                                                    fontSize: "small"
                                                }
                                            }}
                                        />
                                    </SmallTableCell>                                
                                </TableRow>
                            )
                        }
                        </TableBody>
                    </Table>
                </TableContainer>   
            </Box>
            <Box>
                <Button
                    variant="outlined"
                    onClick={handleOpen}
                >
                    Browse Products
                </Button>
            </Box>
            <ProductChooserDialog 
                open={isProductChooserOpen}
                onClose={handleClose}
                setSelectedProducts={handleSelectClose}
                initialSelectedProducts={products}
                axiosInstance={AxiosInstance}
                showAppendAllButton={false}
            />
        </Box>
    )
}

export default ProductForm;