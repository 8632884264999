import React, {useState} from 'react';

//material-ui
import {styled} from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import SmallTableCell from '../components/SmallTableCell';

//custom
import ShrinkedTextField from '../components/ShrinkedTextField';

//utilities
import {toEnumerationString, formatKey, formatValue} from '../utilities';


const StyledGridItem = styled(Grid)({
    padding: 16,
    minHeight: 350,
});

const DeliveryForm = props => {

    const {handleMakeDelivery, customer} = props;
    const [notes, setNotes] = useState("");

    const handleOnChange = event => setNotes(event.target.value);

    const handleOnClick = () => {
        const delivery = {
            delivery_notes: notes,
            customer_id: customer.customer_id
        }
        handleMakeDelivery(delivery);
    }

    return (
        <Grid container width="100%">
            <StyledGridItem item xs={12} md={6}>
                <Typography variant="h5">Customer Info:</Typography>
                <Box padding="8px">
                    <Typography variant="body2"><b>Name: </b>{customer.customer_name}</Typography>
                    <Typography variant="body2"><b>Types: </b>{toEnumerationString(customer.customer_types)}</Typography>
                    <Typography variant="body2"><b>Frequency: </b>{customer.frequency}</Typography>
                    <Typography variant="body2"><b>Sections: </b>{toEnumerationString(customer.formats)}</Typography>
                    {
                        customer.customer_attributes && Object.keys(customer.customer_attributes).length &&
                        Object.keys(customer.customer_attributes)
                        .map((key, index) => {
                            const label = formatKey(key);
                            const value = formatValue(customer.customer_attributes[key])
                            return <Typography key={index} variant="body2"><b>{label + ": "}</b>{value}</Typography>
                        })
                    }
                    <Typography variant="body2"><b>Notes: </b>{customer.notes}</Typography>
                    <Typography variant="body2"><b>Active: </b>{customer.is_active ? "Yes" : "No"}</Typography>
                    <Typography variant="body2"><b>Ftp Username: </b>{customer.ftp_username}</Typography>
                </Box>
            </StyledGridItem>
            <StyledGridItem item xs={12} md={6}>
                <Typography variant="h5">Contacts:</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <SmallTableCell>Name</SmallTableCell>
                                <SmallTableCell>E-Mail</SmallTableCell>
                                <SmallTableCell>Phone Number</SmallTableCell>
                                <SmallTableCell>Notes</SmallTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                 customer.customerContacts.map((item, key) => (
                                    <TableRow key={key}>
                                        <SmallTableCell>{item.contact_name}</SmallTableCell>
                                        <SmallTableCell>{item.contact_email}</SmallTableCell>
                                        <SmallTableCell>{item.contact_phone_number}</SmallTableCell>
                                        <SmallTableCell>{item.contact_notes}</SmallTableCell>
                                    </TableRow>
                                 ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledGridItem>
            <StyledGridItem item xs={12} md={6}>
                <Typography variant="h5">Products:</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <SmallTableCell>Name</SmallTableCell>
                                <SmallTableCell>Year From</SmallTableCell>
                                <SmallTableCell>Year To</SmallTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                 customer.products.map((item, key) => (
                                    <TableRow key={key}>
                                        <SmallTableCell>{item.partTerminologyName + " (" + item.region + ")"}</SmallTableCell>
                                        <SmallTableCell>{item.yearFrom}</SmallTableCell>
                                        <SmallTableCell>{item.yearTo}</SmallTableCell>
                                    </TableRow>
                                 ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledGridItem>
            <StyledGridItem item xs={12} md={6}>
                <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                    <FormControl fullWidth={true}>
                        <ShrinkedTextField 
                            label="Notes"
                            multiline={true}
                            rows={10}
                            variant="outlined"
                            onChange={handleOnChange}
                            value={notes}
                        />
                    </FormControl>
                    <FormControl>
                        <Button variant="outlined" onClick={handleOnClick}>
                            Make Delivery
                        </Button>
                    </FormControl>
                </Box>
            </StyledGridItem>
        </Grid>
    )
}

export default DeliveryForm;