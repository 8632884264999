import React, { useReducer } from "react";  

//material-ui

import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';

// forms
import CustomerForm from './CustomerForm';
import ContactForm from './ContactForm';
import ProductForm from './ProductForm';

//react-hook-form
import { useForm, FormProvider } from 'react-hook-form';

const STEP_LABELS = ['Customer Information', 'Add Contacts', 'Add Product Lines'];



const NEXT = "NEXT";
const PREVIOUS = "PREVIOUS";
const FORM_NEXT = "FORM_NEXT";

const SET_CONTACTS = "SET_CONTACTS";

const SELECT_CLOSE_PRODUCT_CHOOSER = "SELECT_CLOSE_PRODUCT_CHOOSER";
const CLOSE_PRODUCT_CHOOSER = "CLOSE_PRODUCT_CHOOSER";
const OPEN_PRODUCT_CHOOSER = "OPEN_PRODUCT_CHOOSER";

const SET_CUSTOMER = "SET_CUSTOMER";

const createInitialState = (customer = {products: [], customerContacts:[], customerInfo: {is_active: true}}) => {
    return {
        activeStep: 0,
        isProductChooserOpen: false,
        ...customer
    }
};

const reducer = (state, action) => {
    switch(action.type) {
        case NEXT: {
            return {...state, activeStep: state.activeStep + 1}
        }
        case FORM_NEXT: {
            return {...state, activeStep: 1, customerInfo: action.payload}
        }
        case PREVIOUS: {
            return {...state, activeStep: state.activeStep - 1}
        }
        case SET_CONTACTS: {
            return {...state, customerContacts: action.payload}
        }
        case SELECT_CLOSE_PRODUCT_CHOOSER: {
            return {...state, products: action.payload, isProductChooserOpen: false};
        }
        case CLOSE_PRODUCT_CHOOSER: {
            return {...state, isProductChooserOpen: false};
        }
        case OPEN_PRODUCT_CHOOSER: {
            return {...state, isProductChooserOpen: true};
        }
        case SET_CUSTOMER: {
            return {...state, ...action.payload};
        }
        default: {
            return state;
        }
    }
}

const resolveStepForActiveStep = (activeStep, state, dispatch) => {
    switch(activeStep) {
        case 1 : {
            const props = {
                ...state,
                handleContacts: contacts => {
                    dispatch({
                        type: SET_CONTACTS,
                        payload: contacts
                    })
                }
            };
            return <ContactForm {...props}/>
        }
        case 2 : {
            const props = {
                ...state,
                handleOpen: () => dispatch({type: OPEN_PRODUCT_CHOOSER}),
                handleClose: () => dispatch({type: CLOSE_PRODUCT_CHOOSER}),
                handleSelectClose: products => {
                    dispatch({
                        type: SELECT_CLOSE_PRODUCT_CHOOSER,
                        payload: products
                    })
                }
            };
            return <ProductForm {...props}/>
        }
        default : {
            const props = {...state};
            return <CustomerForm {...props}/>
        }
    }
}

const CreateCustomerForm = ({handleUpdateCustomers, customer}) => {

    const [state, dispatch] = useReducer(reducer, createInitialState(customer));
    const {activeStep, customerInfo, customerContacts, products} = state;
    const methods = useForm();
    const {trigger, getValues} = methods;

    const handleNext = () => {
        if(activeStep === 0){
            trigger()
            .then(isValid => {
                if(isValid) {
                    dispatch({type: FORM_NEXT, payload: getValues()});
                }
            });
        }
        else if(activeStep === STEP_LABELS.length - 1 ) {
            const parsedCustomerInfo = {...customerInfo};
            Object
                .keys(customerInfo)
                .filter(key => Array.isArray(customerInfo[key]))
                .forEach(key => {
                    parsedCustomerInfo[key] = customerInfo[key].filter(item => item);
                });
            const customerId = customer ? customer.customerInfo.customer_id: undefined;
            const dto = {...parsedCustomerInfo, customerContacts: [...customerContacts], products: [...products], customer_id: customerId};
            handleUpdateCustomers(dto);
        }
        else {
            dispatch({type: NEXT});
        }
    };

    const handleBack = () => dispatch({type: PREVIOUS});

    return (
        <Box height="90vh" maxHeight="90vh" display="flex" flexDirection="column">
            <Stepper activeStep={activeStep} alternativeLabel>
            {
                STEP_LABELS.map((label) => <Step key={label}><StepLabel>{label}</StepLabel></Step>)
            }
            </Stepper>
            <Box width="100%" flexGrow={1}>
                <FormProvider {...methods}>
                    <form style={{height: "100%"}}>
                        <Box width="100%" height="100%" display="flex" justifyContent="center">
                            <Box width="100%" maxWidth="1000px" padding="8px 16px" border="1px solid #bdbdbd" borderRadius="5px">
                            {   
                                resolveStepForActiveStep(activeStep, state, dispatch)
                            }
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" >
                <Box width="100%" maxWidth="1000px" padding="16px 0" display="flex" justifyContent="space-between">
                    <Button
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button 
                        variant="outlined" 
                        onClick={handleNext}
                    >
                        {activeStep === STEP_LABELS.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default CreateCustomerForm;