import React, {useState, useEffect} from 'react';

// material-ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MaterialTable from 'material-table';

//axios
import {AxiosInstance} from 'jnpsoft-react-utilities/dist/AppBar';
import {exportCsv} from '../utilities';

const API_URL = process.env.REACT_APP_CUSTOMER_DELIVERY_API_URL;

const customColumnConfiguration = [
    { title: "ID", field: "partTerminologyID" },
    { title: "Name", field: "partTerminologyName" },
    { title: "From", field: "yearFrom"},
    { title: "To", field: "yearTo"},
    { title: "Region", field: "region"},
    { 
        title: "Customers", 
        field:"customerNames", 
        render: rowData => (
            <ul>
            {
                rowData.customerNames &&
                rowData.customerNames
                .map(customerName => <li><Typography>{customerName}</Typography></li>)
            }
            </ul>
    )}
]

const ProductCustomerDashboard = props => {

    const [productsAndCustomers, setProductsAndCustomers] = useState([])

    useEffect(() => {
        const loadProductsAndCustomers = async () => {
            AxiosInstance
            .get(API_URL + "/product-with-customers")
            .then(response => response.data)
            .then(data => {
                setProductsAndCustomers(data.map(item => {
                    return {...item.product, customerNames: item.customerNames}
                }));
            })
            .catch(error => console.log(error));
        }
        loadProductsAndCustomers();
    }, [])

    return (
        <Box width="100%" height="100%">
            <MaterialTable 
                title="Products & Customers"
                columns={customColumnConfiguration}
                data={productsAndCustomers}
                options={{
                    grouping: true,
                    filtering: true,
                    pageSize: 10,
                    padding: "dense",
                    exportButton: true,
                    exportCsv: (propsColumns, dataToExport) => exportCsv(propsColumns, dataToExport, "Product & Customers")
                }}
            />
        </Box>
    );
}

export default ProductCustomerDashboard;