import React from 'react';
import CustomerDeliveryPortal from './components/CustomerDeliveryPortal';

import { AuthProvider } from 'jnpsoft-react-utilities/dist/AppBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CustomerDeliveryPortal />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;