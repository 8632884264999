import React, {useEffect} from 'react'

//material-ui
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

//customs
import ShrinkedTextField from '../components/ShrinkedTextField';

//react-hook-form
import {Controller, useFormContext} from 'react-hook-form';

const FREQUENCIES = [ 
    "Monthly",
    "Weekly",
    "By Weekly",
    "Quarterly",
    "Yearly",
    "One Time"
];

const StyledFormGroup = styled('FormGroup')(({ theme }) => ({
    paddingTop: theme.spacing(2)
}));

const CustomerForm = props => {

    const {customerInfo} = props;

    const methods = useFormContext();
    const {register, control, getValues, errors, reset} = methods;

    const validateCheckboxes = (key, errorMessage) => {
        const values = {...getValues()};
        const arrValues = values[key];
        if(arrValues.filter(item => item === false).length === arrValues.length ) {
            return errorMessage;
        }
        return true;
    }

    const validateCustomerType = () => validateCheckboxes("customer_types", "Please enter a customer type");
    const validateFormats = () => validateCheckboxes("formats", "Please enter a format");

    const resolveDefaultValueCheckbox = (value, availablesKey) => {
        const arrValues = customerInfo[availablesKey];
        return arrValues && arrValues.length && arrValues.includes(value);
    }

    const resolveDefaultValueCustomerTypes = value => resolveDefaultValueCheckbox(value, "customer_types");
    const resolveDefaultValueFormats = value => resolveDefaultValueCheckbox(value, "formats");

    useEffect(() => {
        reset();
    }, [reset, customerInfo]);

    const customerInfoFrequency = customerInfo.frequency ? customerInfo.frequency : FREQUENCIES[0];

    return (
        <Grid container justify="space-between">
            <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                    <ShrinkedTextField 
                        label="Customer name"
                        name="customer_name"
                        variant="standard"
                        inputRef={register({required: "Please enter a customer name"})}
                        defaultValue={customerInfo.customer_name}
                        error={errors.customer_name !== undefined}
                        helperText={errors.customer_name && errors.customer_name.message}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                    <InputLabel variant="standard" shrink={true} error={errors.customer_types !== undefined}>Customer Types</InputLabel>
                    <StyledFormGroup row>
                        <FormControlLabel
                            control={<Checkbox value="OE" defaultChecked={resolveDefaultValueCustomerTypes("OE")}/>}
                            name="customer_types[0]"
                            inputRef={register({validate: validateCustomerType})} 
                            label="OE"
                        />
                        <FormControlLabel
                            control={<Checkbox value="Partcat" defaultChecked={resolveDefaultValueCustomerTypes("Partcat")}/>}
                            name="customer_types[1]"
                            inputRef={register({validate: validateCustomerType})} 
                            label="Partcat"
                        />
                        <FormControlLabel
                            control={<Checkbox value="DM" defaultChecked={resolveDefaultValueCustomerTypes("DM")}/>}
                            name="customer_types[2]"
                            inputRef={register({validate: validateCustomerType})} 
                            label="DM"
                        />
                    </StyledFormGroup>
                    <FormHelperText error={errors.customer_types !== undefined} >
                        {errors.customer_types && errors.customer_types[0].message}                  
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" shrink id="frequency-label" error={errors.frequency !== undefined}>
                        Frequency
                    </InputLabel>
                    <Controller 
                        as={ 
                            <Select
                                labelId="frequency-label"
                                placeholder="Select frequency"
                                error={errors.frequency !== undefined}
                                variant="standard" 
                            >
                                {
                                    FREQUENCIES.map((value,index) => <MenuItem key={index} value={value}>{value}</MenuItem>)
                                }
                            </Select>
                        }
                        control={control}
                        name="frequency"
                        rules={{ required: "Please enter a frequency" }}
                        defaultValue={customerInfoFrequency}
                    />
                    <FormHelperText error={errors.frequency !== undefined} >
                        {errors.frequency && errors.frequency.message}                  
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                    <InputLabel variant="standard" shrink={true} error={errors.formats !== undefined}>Format</InputLabel>
                    <StyledFormGroup row>
                        <FormControlLabel
                            control={<Checkbox value="ACESCrossRef" defaultChecked={resolveDefaultValueFormats("ACESCrossRef")}/>}
                            name="formats[0]"
                            inputRef={register({validate: validateFormats})} 
                            label="ACES Cross Ref"
                        />
                        <FormControlLabel
                            control={<Checkbox  value="Excel" defaultChecked={resolveDefaultValueFormats("Excel")}/>}
                            name="formats[1]"
                            inputRef={register({validate: validateFormats})} 
                            label="Excel"
                            
                        />
                        <FormControlLabel
                            control={<Checkbox  value="SQL" defaultChecked={resolveDefaultValueFormats("SQL")}/>}
                            name="formats[2]"
                            inputRef={register({validate: validateFormats})} 
                            label="SQL"
                        />
                        <FormControlLabel
                            control={<Checkbox  value="XML" defaultChecked={resolveDefaultValueFormats("XML")}/>}
                            name="formats[3]"
                            inputRef={register({validate: validateFormats})} 
                            label="XML"
                        />
                        <FormControlLabel
                            control={<Checkbox  value="VIO" defaultChecked={resolveDefaultValueFormats("VIO")}/>}
                            name="formats[4]"
                            inputRef={register({validate: validateFormats})} 
                            label="VIO"
                        />
                    </StyledFormGroup>
                    <FormHelperText error={errors.formats !== undefined} >
                        {errors.formats && errors.formats[0].message}                  
                    </FormHelperText>
                </FormControl>  
            </Grid>
            <Grid item xs={12} md={5}>
                <FormControl margin="normal" fullWidth>
                        <ShrinkedTextField
                            label="Partcat Version" 
                            name="partcat_version"
                            variant="standard"
                            InputLabelProps={{shrink: true}}
                            inputRef={register}
                            defaultValue={customerInfo.partcat_version}
                        />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
                <FormControl margin="normal" fullWidth>
                    <ShrinkedTextField
                        label="Database Type" 
                        name="database_type"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        inputRef={register}
                        defaultValue={customerInfo.database_type}
                    />
                </FormControl>              
            </Grid>
            <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                    <ShrinkedTextField
                        label="Notes" 
                        name="notes"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        inputRef={register}
                        defaultValue={customerInfo.notes}
                    />
                </FormControl>      
            </Grid>  
            <Grid item xs={12}> 
                <FormControl margin="normal" fullWidth>
                    <InputLabel variant="standard" shrink={true}>Active</InputLabel>
                    <StyledFormGroup row>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={customerInfo.is_active}/>}
                            name="is_active"
                            inputRef={register}
                            label="Is Active"
                            variant="standard"
                        />
                    </StyledFormGroup>
                </FormControl>
            </Grid>   
            <Grid item xs={12} md={5}> 
                <FormControl margin="normal" fullWidth>
                    <ShrinkedTextField
                        label="FTP Username" 
                        name="ftp_username"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        inputRef={register}
                        defaultValue={customerInfo.ftp_username}
                    />
                </FormControl> 
            </Grid>     
            <Grid item xs={12}> 
                <FormControl margin="normal" fullWidth>
                    <InputLabel variant="standard" shrink={true}>Ktype Delivery</InputLabel>
                    <StyledFormGroup row>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={customerInfo.ktype_delivery === 'true'}/>}
                            name="ktype_delivery"
                            inputRef={register}
                            label="KType Delivery"
                        />
                    </StyledFormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default CustomerForm