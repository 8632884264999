import React from 'react'

//material-ui

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles'
//redux
import { connect } from 'react-redux';
import { styled } from '@mui/system';
// configuration
import { drawerWidth } from "../configuration/drawerConfiguration";

const menuItems = [
  { text: "Customers", url: "/customers" },
  { text: "Products & Customers", url: "/products-and-customers" }
];
const NavigationDrawer = ({ drawerOpen }) => {

  const theme = useTheme()
  const sxDrawer = {
    width: drawerWidth,
    flexShrink: 0,
  }
  const sxDrawerPaper = {
    width: drawerWidth,
    paddingTop: theme.spacing(6),
  }

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  return (
    <Drawer
      PaperProps={{ sx: sxDrawerPaper }}
      variant="persistent"
      open={drawerOpen}
      style={{ display: drawerOpen ? 'block' : 'none' }}
      sx={sxDrawer}
    >
      <Offset />
      <List
        component='nav'
        aria-labelledby='drawer-list'
      >
        {
          menuItems.map((items, index) => (
            <Link key={items.text} href={items.url} >
              <ListItem button >
                <ListItemText primary={items.text} />
              </ListItem>
            </Link>
          ))
        }
      </List>
    </Drawer>
  );
}

const mapStateToProps = state => ({
  drawerOpen: state.appBarTabs.drawerOpen,
});

export default connect(mapStateToProps)(NavigationDrawer);