import { CsvBuilder } from 'filefy';

export const toEnumerationString = values => {
    if(values === undefined) {
        return values;
    }
    if(values.length === 1) {
        return values[0];
    }
    else if(values.length === 2) {
        return values.join(" & ");
    }
    else if(values.length) {
        const valuesToEnumerate = [...values];
        const lastValue = valuesToEnumerate.pop();
        return valuesToEnumerate.join(", ") + " & " + lastValue;
    }
    return "";
}

export const formatKey = key => {
    const keyParts = key.split("_");
    const formattedKeys = 
        keyParts
        .map(part => {
            if(part.length === 1) {
                return part.toUpperCase();
            }
            return part[0].toUpperCase() + part.slice(1);
        });
    return formattedKeys.join(" ");
}

export const formatValue = value => {
    if(value === "true")
        return "Yes";
    if(value === "false")
        return "No";
    return value;
}

export const exportCsv = (propsColumns, dataToExport, filename) => {
    const exportColumns = propsColumns
    .filter(columnDef => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
    })
    .sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder) ? 1 : -1);
    const data = dataToExport.map(rowData =>
        exportColumns.map(columnDef => {
            let dataForCell = rowData[columnDef.field];

            if(typeof(dataForCell) === 'object') {
                dataForCell = JSON.stringify(dataForCell);
            }
            return dataForCell;
        })
    );
    const builder = new CsvBuilder(`${filename}.csv`);
    builder
      .setColumns(exportColumns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile();
}