import React from 'react'

//material-ui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const JNPDialog = ({children, title, ...others}) => {
    return (
        <Dialog {...others} fullWidth>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default JNPDialog;