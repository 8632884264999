import React, {useState} from 'react';

//material-ui
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import SmallTableCell from '../components/SmallTableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';

//customs
import ShrinkedTextField from '../components/ShrinkedTextField';

//react-hook-form
import {useForm} from 'react-hook-form';



const ContactForm = props => {

    const {customerContacts, handleContacts} = props;
    const [addContactMode, setAddContactMode] = useState(false);

    const handleOpenAddContactMode = () => {
        setAddContactMode(true);
    }

    const {trigger, register, errors, getValues, reset} = useForm();

    const isContactEqual = (c1, c2) => {
        return c1.contact_name === c2.contact_name
            && c1.contact_email === c2.contact_email;
    }

    const handleAddContact = () => {
        trigger()
        .then(isValid => {
            if(isValid) {
                const newContact = getValues();
                if(customerContacts.filter(item => isContactEqual(item, newContact)).length === 0) {
                    const newCustomerContacts = [...customerContacts, newContact];
                    handleContacts(newCustomerContacts);
                    reset();
                    setAddContactMode(false);
                }
            }
        })
    }

    const handleRemoveContact = key => {
        let newCustomerContacts = [...customerContacts];
        newCustomerContacts.splice(key, 1);
        handleContacts(newCustomerContacts);
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Box width="100%" flexGrow={1}>
                <TableContainer>
                    <Table
                        size="small"
                        style={{
                            fontSize: "small"
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <SmallTableCell>Contact Name</SmallTableCell>
                                <SmallTableCell>Contact E-mail</SmallTableCell>
                                <SmallTableCell>Phone Number</SmallTableCell>
                                <SmallTableCell>Notes</SmallTableCell>
                                <SmallTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            customerContacts
                            .sort((o1, o2) => {
                                if(o1.contact_name !== o2.contact_name) {
                                    return o1.contact_name.localeCompare(o2.contact_name);
                                }
                                return o1.contact_email.localeCompare(o2.contact_email);
                            })
                            .map((contact, key) => {
                                return (
                                    <TableRow key={key}>
                                        <SmallTableCell>{contact.contact_name}</SmallTableCell>
                                        <SmallTableCell>{contact.contact_email}</SmallTableCell>
                                        <SmallTableCell>{contact.contact_phone_number}</SmallTableCell>
                                        <SmallTableCell>{contact.contact_notes}</SmallTableCell>
                                        <SmallTableCell>
                                            <IconButton size="small" onClick={() => handleRemoveContact(key)}><DeleteForeverIcon /></IconButton>
                                        </SmallTableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            addContactMode && 
                            <TableRow>
                                <SmallTableCell>
                                    <ShrinkedTextField 
                                        label="Contact Name"
                                        name="contact_name"
                                        variant="standard"
                                        inputRef={register({required: "Please enter a contact name"})}
                                        error={errors.contact_name !== undefined}
                                        helperText={errors.contact_name && errors.contact_name.message}
                                        inputProps={{
                                            style: {
                                                fontSize: "small"
                                            }
                                        }}
                                    />
                                </SmallTableCell>
                                <SmallTableCell>
                                    <ShrinkedTextField 
                                        label="Contact Email"
                                        name="contact_email"
                                        variant="standard"
                                        inputRef={register({required: "Please enter a contact email"})}
                                        error={errors.contact_email !== undefined}
                                        helperText={errors.contact_email && errors.contact_email.message}
                                        inputProps={{
                                            style: {
                                                fontSize: "small"
                                            }
                                        }}
                                    />
                                </SmallTableCell>
                                <SmallTableCell>
                                    <ShrinkedTextField 
                                        label="Phone Number"
                                        name="contact_phone_number"
                                        variant="standard"
                                        inputRef={register}
                                        inputProps={{
                                            style: {
                                                fontSize: "small"
                                            }
                                        }}
                                    />
                                </SmallTableCell>
                                <SmallTableCell>
                                    <ShrinkedTextField 
                                        label="Contact Notes"
                                        name="contact_notes"
                                        variant="standard"
                                        inputRef={register}
                                        inputProps={{
                                            style: {
                                                fontSize: "small"
                                            }
                                        }}
                                    />
                                </SmallTableCell>
                                <SmallTableCell>
                                    <IconButton size="small" onClick={handleAddContact}><CheckIcon /></IconButton>
                                </SmallTableCell>
                        </TableRow>
                        }
                        </TableBody>
                    </Table>
                </TableContainer>   
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Button variant="outlined" onClick={handleOpenAddContactMode} disabled={addContactMode}>Add Contact</Button>
            </Box>
        </Box>        
    )
}

export default ContactForm;